import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-389f8f78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"color":"#1890ff"} }
const _hoisted_2 = {
  key: 0,
  style: { textAlign: 'center', paddingTop: '12px', height: '48px', lineHeight: '32px', backgroundColor: '#fff' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_PrintOrderTable = _resolveComponent("PrintOrderTable")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "content" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        onChange: _ctx.changeTabs
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "附件列表"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.repositories, (items) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: items.contractId,
                  style: {"background-color":"#9CC1E9","padding":"10px"}
                }, [
                  _createVNode(_component_a_card, {
                    size: "small",
                    title: items.contractName + ' 附件列表'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list, {
                        class: "demo-loadmore-list",
                        loading: _ctx.loading,
                        "item-layout": "horizontal",
                        "data-source": items.attachment
                      }, {
                        renderItem: _withCtx(({ item }) => [
                          _createVNode(_component_a_list_item, null, {
                            actions: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: item.checked,
                                "onUpdate:checked": ($event: any) => ((item.checked) = $event)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.checked ? '取消打印' : '确认打印'), 1)
                                ]),
                                _: 2
                              }, 1032, ["checked", "onUpdate:checked"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_list_item_meta, null, {
                                title: _withCtx(() => [
                                  _createElementVNode("span", null, [
                                    _createElementVNode("i", _hoisted_1, _toDisplayString(item.fileName) + " 附件", 1)
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_a_tag, { color: 'green' }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.pageSize || 0) + "页", 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["loading", "data-source"])
                    ]),
                    _: 2
                  }, 1032, ["title"])
                ]))
              }), 128)),
              (_ctx.repositories.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _ctx.loadMore
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("添加打印任务")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "2",
            tab: "我的订单"
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey === '2')
                ? (_openBlock(), _createBlock(_component_PrintOrderTable, {
                    key: 0,
                    userName: _ctx.userName,
                    type: '2'
                  }, null, 8, ["userName"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey", "onChange"])
    ]),
    _: 1
  }))
}