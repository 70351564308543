
// 忽略文件中所有警告
// /* eslint-disable */
import { 
  defineComponent, 
  onMounted, 
  ref
  // getCurrentInstance,
  // toRaw,
} from 'vue'
import { message as Message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { 
  // addPrintItem,
  queryPrintPrice
  // parseAddress
} from '@/api/manage'
import { useRouter } from 'vue-router'
import { ReloadOutlined } from '@ant-design/icons-vue'
// 忽略下一行警告

// eslint-disable-next-line
import PrintModel from '@/components/PrintModel.vue' // @ is an alias to /src
import ExpressOrder from '@/components/ExpressOrder.vue' // @ is an alias to /src
import PrintOrderModal from '@/views/component/PrintOrderModal.vue'
import { returnQuery } from '@/utils/index'

export default defineComponent({
  name: 'sealPrinting',
  components: {
    PrintModel,
    ReloadOutlined,
    ExpressOrder,
    PrintOrderModal
  },
  setup (props, context) {
    // const { proxy } = getCurrentInstance() // 使用proxy代替ctx，因为ctx只在开发环境有效
    // getCurrentInstance别人的文章说只适用于调试，不能用于线上
    
    const router = useRouter();
    const store = useStore()
    const repositories = ref<any[]>([])
    const contractName = ref()
    repositories.value = store.getters.repositoriesArr
    repositories.value.forEach(elem => {
      //标题
      contractName.value = elem.contractName

      elem.attachment = elem.attachment.filter(item=> item.checked && item.pageSize).map(item => {
        return {
          amountPayable: 0,
          dmColor: "2",
          dmCopies: "1",//打印份数
          dmDuplex:item.printType + '' || "2",
          dmOrientation: "1",
          dmPaperSize: "9",
          jobFile: item.signPdfFile,
          jobFileName: item.fileName,
          jpPageRange: "",
          jpTotalPage: item.pageSize,
          jpAutoScale: '4',
          jpAutoAlign: 'z5',
          isPreview: '0',
          dmPrintQuality: '-4',
          contractId: elem.id,
          createTime: item.createTime,
          isDoublePrint:item.printType + '',
          contractProperties:"2"//联动纸张尺寸&打印色彩&打印方式
        }
      })
    })
    repositories.value = repositories.value.filter(item=> item.attachment.length)
    const address = ref([])
    const expressOrder = ref(null)
    const note = ref<string>('')
    const contractId = ref<any>('')
    const userName = ref<any>('')
    const printOrderModal = ref(null)
    
    const totalPayable = ref<number>(0)
    const loading = ref<boolean>(false)
    const expressChecked = ref<boolean>(true)
    const loadingMore = ref<boolean>(false)
    const changeChecked = (checked: boolean) => {
      expressChecked.value = checked
    }

    const refArr = ref([])
    const printPrice = ref()
    const handleClick = (e: Object) => {
      // console.log('home组件函数触发====', e)
    }
    const pushRef = (e) => {
      let len = repositories.value.reduce((sum, item) => {
        return sum + item.attachment.length
      }, 0)
      if (refArr.value.length > 0 && refArr.value.length === len) return;
      refArr.value.push(e)
    }

    const returnPage = () => {
      router.go(-1)
    }
    // 修改总价
    const setTotalPayable = (data: any = {}): void => {
      for (let index = 0; index < repositories.value.length; index++) {
        const element = repositories.value[index];
        
        if (element.contractId === data.contractId) {
          element.attachment = element.attachment.map(item => {
            if (item.jobFile === data.jobFile) {
              item = Object.assign({}, data)
            }
            return item
          })
        }
      }
    }

    //提交打印
    function onSubmitOrder () {
      let promiseArr = []
      refArr.value.forEach((item, index) => {
        promiseArr.push(
          new Promise((resolve,reject) => {
            console.log('========refArr',refArr.value)
            item.onSubmit((video) => {
              if (video) {
                resolve(video)
              } else {
                reject()
              }
            })
          })
        )
      })
      Promise.all(promiseArr).then((result) => {
        if (expressChecked.value) {
          expressOrder.value.onSubmit((res) => {
            if (res) {
              // const { businessType, receiveAddress, receiveName, receivePhone, valueArea } = expressOrder.value.formState
              let obj = {
                ...expressOrder.value.formState
              }
              printOrderModal.value.onSubmit(note.value, obj, repositories.value)
            } else {
              Message.warning('请完善快递信息')
            }
          })
        } else {
          printOrderModal.value.onSubmit(note.value, null, repositories.value)
        }
      }).catch((error) => {
        console.log('error-----------', error)
        Message.warning('请填写完整必要的参数！！')
      })
    }

    onMounted(async () => {
      // 获取路由参数的两种方式
      // verify:'contractId=' + contractId.value + '&userName=' + userName.value
      const data: any = router.currentRoute.value.query.verify
      const query: any = returnQuery(data)
      contractId.value = query.contractId
      userName.value = query.userName

      //价格
      const result: any = await queryPrintPrice()
      printPrice.value = result
    })
    return {
      handleClick,
      repositories,
      expressChecked,
      pushRef,
      checked: ref(true),
      loading,
      address,
      note,
      userName,
      contractId,
      loadingMore,
      returnPage,
      printPrice,
      // parmse,
      contractName,
      totalPayable,
      setTotalPayable,
      expressOrder,
      printOrderModal,
      onSubmitOrder,
      changeChecked
    }
  }
})
