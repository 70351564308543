
// 忽略文件中所有警告
// /* eslint-disable */
import { defineComponent, onMounted, ref, toRaw, watch } from 'vue'
import { message as Message, Modal } from 'ant-design-vue'
import { sealPdfList } from '@/api/manage'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { returnQuery } from '@/utils/index'
import PrintOrderTable from '@/views/component/PrintOrderTable.vue'

// 忽略下一行警告
// eslint-disable-next-line
export default defineComponent({
  name: 'sealPrint',
  components: {
    PrintOrderTable
  },
  setup () {
    const store = useStore()
    const router = useRouter();
    const repositories = ref<any>([])
    const loading = ref(false)
    const userName = ref<any>('')
    const takeMode = ref('JSXJ')
    const activeKey = ref('1')
    const contractId = ref<any>('')
    //tab切换
    const changeTabs = (e) => {
      activeKey.value = e
      if (e == '1') {
        getContractPrintData(contractId.value)
      }
    }
    
    //添加打印任务
    async function loadMore () {
      const data = toRaw(repositories.value)
      console.log('========data',data)
      let arr = []
      data.forEach(item => {
        item.attachment.forEach(element => {
          if (element.checked && element.pageSize) {
            arr.push(element)
          }
        });
      })
      if (!arr.length) {
        Message.warning('没有打印任务')
        return
      }
      //将附件数据缓存
      store.commit('setRepositories', data)
      let code = 'contractId=' + contractId.value + '&userName=' + userName.value
      router.push({
        path: '/sealPrinting',
        query: {
          verify: window.btoa(escape(code)),
          takeMode: takeMode.value
        }
      })
    }
    // 获取合同文件列表
    const getContractPrintData = async (id: string) => {
      loading.value = true
      sealPdfList({ id:id}).then(res=> {
        // console.log('sealPdfList====', res);
        if (res) {
          let resp = res.pdfList
          if (resp.length) {
            resp.forEach(el => el.checked=false)
            repositories.value = [{
              contractName: res.title,
              contractId: res.id,
              attachment: resp
            }]
            console.log('=========repositories.value',repositories.value)
          } else {
            Message.error('添加失败,无附件')
          }
        } else {
          Message.error('查询失败')
        }
        loading.value = false
      }).catch(err=> {
        console.log('sealPdfList--------',err);
        Message.error('请求失败')
        loading.value = false
      })
      
    }
    onMounted(() => {
      
      const data: any = router.currentRoute.value.query.verify
      const query: any = {}||returnQuery(data)
      if (data) {
        contractId.value = query.id
        userName.value = query.userName
        if (query.tabs) {
          changeTabs(query.tabs)
        }
      }
      
      contractId.value = query.id || 'ae5b00a0-e4d6-4c6a-ba41-c650c91ce036'
      userName.value = query.userName || 'lp'
      console.log('=========参数',contractId.value)
      if (contractId.value) {
        getContractPrintData(contractId.value)
      } else {
        Message.error('没有合同id')
      }
      
    })
    return {
      contractId,
      // handleClick,
      repositories,
      checked: ref(true),
      loading,
      loadMore,
      activeKey,
      userName,
      takeMode,
      changeTabs,
     
    }
  }
})
